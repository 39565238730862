import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useState, createContext } from "react";

import DefaultLayoutEntry from "./layouts/DefaultLayout.entry";
import ClaimListEntry from './pages/ClaimList.entry';
import ClaimDetailEntry, { path as ClaimDetailPath } from './pages/ClaimDetail.entry';
import ClaimNewEntry, { path as ClaimNewPath } from './pages/ClaimNew.entry';
import IncompleteBankClaimListEntry, { path as IncompleteBankClaimListPath } from './pages/IncompleteBankClaimList.entry';
import BankEditEntry, { path as BankEditPath } from './pages/BankEdit.entry';

export const LoginContext = createContext();

function App() {
  const [userEmail, setUserEmail] = useState(null);

  return (
    <LoginContext.Provider value={{ userEmail, setUserEmail }}>
      <Router>
        <DefaultLayoutEntry>
          <Routes>
            <Route exact path="/" element={<ClaimListEntry />} />
            <Route exact path={ClaimDetailPath} element={<ClaimDetailEntry />} />
            <Route exact path={ClaimNewPath} element={<ClaimNewEntry />} />
            <Route exact path={IncompleteBankClaimListPath} element={<IncompleteBankClaimListEntry />} />
            <Route exact path={BankEditPath} element={<BankEditEntry />} />
          </Routes>
        </DefaultLayoutEntry>
      </Router>
    </LoginContext.Provider>
  );
}

export default App;
