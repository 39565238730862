import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { Pagination } from 'antd';
import * as dayjs from 'dayjs';

import ClaimList from "./ClaimList";
import Loading from '../components/Loading';
import { SEARCH_BY, CLAIM_STATUS_TH } from '../utils/constant';

const ClaimListEntry = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [claimsData, setClaimsData] = useState(null);
  //const [page, setPage] = useState(1);
  const page = parseInt(searchParams.get('page') || '1');
  const status = searchParams.get('status') || 'ALL';
  const assignee = searchParams.get('assignee') || '';
  const pageSize = 50;
  const [isLoading, setIsLoading] = useState(true);

  const possibleSearchByChoices = [SEARCH_BY.IDENTITY_NUMBER, SEARCH_BY.POLICY_NUMBER, SEARCH_BY.INSURED_NAME, SEARCH_BY.REFERENCE_NUMBER, SEARCH_BY.POLICY_HOLDER_NAME];
  const searchBy = useMemo(() => {
    const searchByChoice = possibleSearchByChoices.find(i => searchParams.get(i));
    const _searchBy = searchByChoice && [searchByChoice, searchParams.get(searchByChoice)];
    return _searchBy;
  }, [searchParams]);
  const searchText = searchBy?.[1];
  const searchOption = searchBy?.[0];

  const { claims, total } = useMemo(() => {
    if (!claimsData) return {};

    function mapper(i) {
      return {
        submitted_at: dayjs(i.submitted_date).format('DD/MM/YYYY HH:mm:ss'),
        claim_number: i.id,
        policy_number: i.policy_number,
        policy_holder_name: i.policy_holder_name,
        insured_name: i.insured_name,
        insured_id: i.insured_id,
        treatment_date: dayjs(i.treatment_start).format('DD/MM/YYYY'),
        claim_amount: i.claimed_amount ? parseFloat(i.claimed_amount).toLocaleString('th-TH', { style: 'currency', currency: 'THB' }) : '',
        status: CLAIM_STATUS_TH[i.status],
        assignee: i.assignee,
        claim_type: i.treatment_type,
        reference_number: i.reference_number,
        detail_link: '/claim/' + i.id
      }
    }

    const claims = claimsData.claims.map(mapper);
    const total = claimsData.claim_count;

    return { claims, total }
  }, [claimsData]);

  // fetching when needed
  async function fetchData() {
    try {
      setIsLoading(true);
      let query = `?page=${page}&size=${pageSize}`;
      if (searchBy) {
        query += `&${searchOption}=${searchText}`
      }
      if (status !== 'ALL') {
        query += `&status=${status}`
      }
      if(assignee) {
        query += `&assignee=${assignee}`
      }
      const claimsData = await axios.get(`/api/claims${query}`).then(res => res.data);
      await new Promise(res => setTimeout(res, 300));
      setClaimsData(claimsData);
      setIsLoading(false);
      window.scrollTo(0, 0);
    } catch(err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchData();
  }, [page, status, assignee]);

  function pageChangeHandler(pageNum) {
    searchParams.set('page', pageNum);
    navigate('/?' + searchParams.toString());
  }

  function statusChangeHandler(filterStatus) {
    searchParams.set('status', filterStatus);

    navigate('/?' + searchParams.toString());
  }

  function assigneeChangeHandler(assignee) {
    if (assignee) {
      searchParams.set('assignee', assignee);
    } else {
      searchParams.delete('assignee');
    }
    navigate('/?' + searchParams.toString());
  }

  function searchHandler(input, option) {
    possibleSearchByChoices.forEach(i => searchParams.delete(i));
    input && searchParams.set(option, input);
    navigate('/?' + searchParams.toString());
    fetchData();
  }

  //return (<div>hello claims entry</div>)
  const pagination = (<Pagination onChange={pageChangeHandler} pageSize={pageSize} current={page} total={total} showSizeChanger={false}></Pagination>);

  return (
    <>
      { isLoading ? <Loading></Loading> : null }
      <ClaimList
        claims={claims}
        pagination={pagination}
        status={status}
        assignee={assignee}
        searchText={searchText}
        searchOption={searchOption}
        searchHandler={searchHandler}
        statusChangeHandler={statusChangeHandler}
        assigneeChangeHandler={assigneeChangeHandler}
      >
      </ClaimList>
    </>
  );
}

export default ClaimListEntry;

