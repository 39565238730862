import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Select, Table, Radio, Space } from 'antd';

import styles from './ClaimList.module.scss';
import { CLAIM_FILTER, SEARCH_BY, CLAIM_STATUS_TH } from '../utils/constant';
import { isEmpty } from 'lodash/fp';

const ClaimList = ({ claims, pagination, status, searchText, searchOption, statusChangeHandler, searchHandler, assigneeChangeHandler, assignee }) => {
  const [searchOpt, setSearchOpt] = useState(searchOption || SEARCH_BY.IDENTITY_NUMBER);

  const columns = [
    {
      title: 'วันที่สร้าง', // 'Submitted At'
      dataIndex: 'submitted_at'
    },
    {
      title: 'เลขกรมธรรม์.', // 'Policy Number'
      dataIndex: 'policy_number'
    },
    {
      title: 'เลข Reference', // 'Ref. Number'
      dataIndex: 'reference_number'
    },
    {
      title: 'ชื่อผู้ถือกรมธรรม์', // 'Policy Holder Name'
      dataIndex: 'policy_holder_name'
    },
    {
      title: 'ผู้รับความคุ้มครอง', // 'Insured'
      dataIndex: 'insured_name'
    },
    {
      title: 'เลขบัตรปชช.', // 'Identification'
      dataIndex: 'insured_id'
    },
    {
      title: 'วันเข้ารักษา', // 'Treatement Date'
      dataIndex: "treatment_date"
    },
    {
      title: 'ยอดเคลม', // 'Claim Amount'
      dataIndex: 'claim_amount'
    },
    {
      title: 'ผู้ดูแลเคลม', // 'Assignee'
      dataIndex: 'assignee'
    },
    {
      title: 'สถานะ',
      dataIndex: 'status'
    },
    {
      title: '-',
      dataIndex: 'detail_link',
      render(text, record, index) {
        return (
          <Link to={text} data-cy="claim-link">{'ดูรายละเอียด' /* detail */ }</Link>
        )
      }

    }
  ];

  const selectBefore = (
    <Select defaultValue={searchOpt} onChange={setSearchOpt}>
      <Select.Option value={SEARCH_BY.POLICY_NUMBER}>{'เลขกรมธรรม์' /* Policy number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.IDENTITY_NUMBER}>{'เลขบัตรปชช.' /* Identification number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.REFERENCE_NUMBER}>{'เลข Reference' /* Reference number */ }</Select.Option>
      <Select.Option value={SEARCH_BY.POLICY_HOLDER_NAME}>{'ชื่อผู้ถือกรมธรรม์' /* policy holder name */ }</Select.Option>
      <Select.Option value={SEARCH_BY.INSURED_NAME}>{'ชื่อผู้ทำประกัน' /* Insured name */ }</Select.Option>
    </Select>
  );

  return (
    <>
      <div className={styles.searchWrapper}>
        <Input.Search addonBefore={selectBefore} onSearch={(text) => searchHandler(text, searchOpt)} defaultValue={searchText}></Input.Search>
      </div>
      <div className={styles.bodyAndSidebar}>
        <div className={styles.sidebar}>
        <div className={styles.sidebarTopic}>{'สถานะ' /* Status */ }</div>
        <Radio.Group onChange={(e) => statusChangeHandler(e.target.value)} value={isEmpty(status) ? CLAIM_FILTER.ALL : status}>
          <Space direction="vertical">
            <Radio value={CLAIM_FILTER.ALL}>ทั้งหมด</Radio>
            <Radio value={CLAIM_FILTER.SUBMITTED}>{CLAIM_STATUS_TH.SUBMITTED}</Radio>
            <Radio value={CLAIM_FILTER.DOCUMENT_APPROVED}>{CLAIM_STATUS_TH.DOCUMENT_APPROVED}</Radio>
            <Radio value={CLAIM_FILTER.DOCUMENT_INCOMPLETE}>{CLAIM_STATUS_TH.DOCUMENT_INCOMPLETE}</Radio>
            <Radio value={CLAIM_FILTER.CANCELLED}>{CLAIM_STATUS_TH.CANCELLED}</Radio>
            <Radio value={CLAIM_FILTER.INCOMPLETE_BANK_DETAIL}>{CLAIM_STATUS_TH.INCOMPLETE_BANK_DETAIL}</Radio>
            <Radio value={CLAIM_FILTER.PENDING_SEND_TO_SUNCARE}>{CLAIM_STATUS_TH.PENDING_SEND_TO_SUNCARE}</Radio>
          </Space>
        </Radio.Group>
        <div className={styles.sidebarTopic}>{'ผู้ทำเคลม' /* Assignee */ }</div>
        <Radio.Group onChange={(e) => assigneeChangeHandler(e.target.value)} value={assignee}>
          <Space direction="vertical">
            <Radio value={''}>ทั้งหมด</Radio>
            <Radio value={'me'}>ของฉัน</Radio>
          </Space>
        </Radio.Group>
        </div>
        <div>
          <Link to={'/claim/new'}>{'สร้างเคลม' /* New Claim */ }</Link>
          <div className={styles.table}>
            <Table pagination={false} columns={columns} dataSource={claims} data-cy="claims-table"></Table>
          </div>
          {pagination}
        </div>
      </div>
    </>
  );
};

ClaimList.propTypes = {
  claims: PropTypes.array
};

ClaimList.defaultProps = {
};

export default ClaimList;